<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div class="uk-width-1-1">
      <h1 class="uk-heading-line">
        {{ headerTitle }}
      </h1>
    </div>
    <edit
      :id="id"
      :images="images"
      :list-role="listRole"
      :list-facility-type="listFacilityType"
      :loading-detail="loadingDetail"
      :set-toggle-modal-add-edit-discard="setToggleModalAddEditDiscard"
      :get-toggle-modal-add-edit-discard="getToggleModalAddEditDiscard"
      :set-data-modal-add-edit="setDataModalAddEdit"
    />
    <modal-add-edit-discard 
      :set-toggle-modal-add-edit-discard="setToggleModalAddEditDiscard"
      :get-toggle-modal-add-edit-discard="getToggleModalAddEditDiscard"
      :handle-modal-button="handleModalEdit"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { notificationSuccess } from '@/utils/notification'
import { PREFIX_IMAGE } from "@/utils/constant"
import Edit from './Edit'
import getHeaderTitle from '@/utils/header-title'
import ModalAddEditDiscard from '@/components/globals/modals/ModalAddEditDiscard'

export default {
  components: {
    Edit,
    ModalAddEditDiscard
  },
  props: {
    id:{
      required: true,
      type: String
    },
    dataRole: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      loadingDetail: true
    }
  },
  computed: {
    ...mapGetters({
      listRole: 'role/list_role',
      listFacilityType: 'masterData/list_master_data',
      getDataModalAddEdit: 'user/get_data_modal_add_edit',
      getToggleModalAddEditDiscard: 'user/get_toggle_modal_add_edit_discard'
    }),
    headerTitle() {
      return getHeaderTitle(this.$route.fullPath)
    }
  },
  async mounted() {
    this.loadingDetail
    await this.actionGetListMasterData({data_type: 'facility_types'})
    await this.actionGetFacilityUser({ user_id: this.id, is_all: true })
    await this.actionGetListRole({page: 1, limit: 10})
    this.loadingDetail = false
  },
  methods: {
    ...mapActions({
      actionGetFacilityUser: 'user/getFacilityUser',
      actionGetListMasterData: 'masterData/getListMasterData',
      actionGetListRole: 'role/getListRole',
      updateUser: 'user/updateUser'
    }),
    ...mapMutations({
      setDataModalAddEdit: 'user/SET_DATA_MODAL_ADD_EDIT',
      setToggleModalAddEditDiscard: 'user/SET_TOGGLE_MODAL_ADD_EDIT_DISCARD'
    }),
    handleModalEdit () {
      this.setToggleModalAddEditDiscard({
        ...this.getToggleModalAddEditDiscard,
        type: 'save',
        loadingTable: false,
        loadingPopUp: true
      })
      const payload = {
        id: this.id,
        reqBody: {...this.getDataModalAddEdit}
      }
      this.updateUser(payload).then(result => {
        if (result.message == 'OK') {
          setTimeout(() => {
            this.setToggleModalAddEditDiscard({
              ...this.getToggleModalAddEditDiscard,
              loadingTable: false,
              loadingPopUp: false
            })
            notificationSuccess('Edit User Successful')
          }, 500)
          setTimeout(() => {
            this.$router.push({ name: 'User' })
            this.setToggleModalAddEditDiscard({
              targetModals: false,  
              title: '',
              description: '',
              type: '',
              customTitleBtnOk: '',
              customTitleBtnCancel: '',
              customClass: '',
              loadingTable: false,
              loadingPopUp: false
            })
          }, 1000)
        } else {
          this.setToggleModalAddEditDiscard({
            targetModals: false,  
            title: '',
            description: '',
            type: '',
            customTitleBtnOk: '',
            customTitleBtnCancel: '',
            customClass: '',
            loadingTable: false,
            loadingPopUp: false
          })
        }
      })
    }
  }
}
</script>
