<template>
  <div>
    <div class="uk-card uk-card-default uk-margin-top base-card">
      <div
        v-if="loadingDetail"
        class="uk-flex uk-flex-center uk-flex-middle"
      >
        <div uk-spinner />
      </div>
      <div
        v-else
        class="uk-form-stacked"
      >
        <div
          class="uk-child-width-expand@s uk-grid-medium"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <div
              class="uk-child-width-expand@s uk-grid-medium"
              uk-grid
            >
              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  User ID
                </label>
                <div class="uk-form-controls">
                  <input
                    name="user_id"
                    :value="detail_user.user_code || '-'"
                    class="uk-input readonly"
                    type="text"
                    autocomplete="off"
                    readonly
                  >
                </div>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  User Name
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="formUser.name"
                    v-validate="'required|max:50'"
                    name="username"
                    class="uk-input"
                    type="text"
                    autocomplete="off"
                    placeholder="Enter User Name"
                    :class="{'uk-form-danger': errors.has('username')}"
                  >
                </div>
                <span
                  v-show="errors.has('username')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('username') }}</span>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Email
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="formUser.email"
                    v-validate="'required|email|max:50'"
                    name="email"
                    class="uk-input"
                    type="text"
                    autocomplete="off"
                    placeholder="Enter Email"
                    :class="{'uk-form-danger': errors.has('email')}"
                  >
                </div>
                <span
                  v-show="errors.has('email')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('email') }}</span>
              </div>
            </div>
          </div>
          
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <div
              class="uk-child-width-expand@s uk-grid-medium"
              uk-grid
            >
              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Phone Number
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="formUser.phone"
                    v-validate="'required|max:20'"
                    name="phone_number"
                    class="uk-input"
                    type="text"
                    autocomplete="off"
                    placeholder="Enter Phone Number"
                    onkeypress="return /^[0-9]*$/i.test(event.key)"
                    :class="{'uk-form-danger': errors.has('phone_number')}"
                    @input="handlePhoneNumber($event.target.value)"
                    @blur="validasiInput($event.target.value)"
                  >
                </div>
                <span
                  v-show="errors.has('phone_number')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('phone_number') }}</span>
              </div>
              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Farmer Name
                </label>
                <div class="uk-form-controls">
                  <multiselect
                    v-model="detail_user.partner"
                    label="name" 
                    name="partner" 
                    track-by="id" 
                    placeholder="Select Farmer Name" 
                    open-direction="bottom" 
                    :options="listPartner.data" 
                    :loading="isLoadingSearch"
                    :searchable="true"
                    :max-height="200"
                    :show-no-results="true"
                    @select="handleSelectPartner"
                    @search-change="asyncFindPartner"
                  >
                    <span slot="noResult">Oops! Data tidak ditemukan.</span>
                  </multiselect>
                </div>
                <span
                  v-show="errors.has('partner')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('partner') }}</span>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Role
                </label>
                <div class="uk-form-controls">
                  <multiselect 
                    v-model="detail_user.role" 
                    v-validate="'required'"
                    label="name" 
                    name="role" 
                    track-by="id" 
                    placeholder="Select Role" 
                    open-direction="bottom" 
                    :options="listRole.data" 
                    :searchable="true"
                    :max-height="200"
                    :show-no-results="true"
                    @select="handleSelectRole"
                  >
                    <span slot="noResult">Oops! Data tidak ditemukan.</span>
                  </multiselect>
                </div>
                <span
                  v-show="errors.has('role')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('role') }}</span>
              </div>
            </div>
          </div>

          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <div
              class="uk-child-width-expand@s uk-grid-medium"
              uk-grid
            >
              <div
                v-if="detail_user.status == 1 || detail_user.status == 0"
                class="uk-inline uk-width-1-1"
              >
                <label class="uk-form-label">
                  Status
                </label>
                <div class="uk-form-controls">
                  <label class="switch">
                    <input
                      v-if="formUser.status == 1"
                      class="switcher"
                      name="status"
                      type="checkbox"
                      checked
                      @change="handleStatus"
                    >
                    <input
                      v-else
                      class="switcher"
                      name="status"
                      type="checkbox"
                      @change="handleStatus"
                    >
                    <span class="slider round" />
                  </label>
                  <span :class="formUser.status == 1 ? 'label-switcher active ' : 'label-switcher'">{{ formUser.status == 1 ? status.ACTIVE : status.INACTIVE }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin-top base-card">
      <div
        class="uk-child-width-expand@s uk-grid-medium"
        uk-grid
      >
        <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-1@l">
          <h4 class="uk-heading-line">
            Facility
          </h4>
          <div
            v-if="loadingDetail"
            class="uk-flex uk-flex-center uk-flex-middle"
          >
            <div uk-spinner />
          </div>
          <div
            v-else
            class="uk-form-stacked form-facility-fields"
          >
            <div
              v-for="(data, index) in list_facility_user"
              :key="index"
              class="uk-child-width-expand@s uk-grid-small" 
              uk-grid
            >
              <div class="uk-width-1-3">
                <div class="uk-form-controls uk-position-relative withlabel">
                  <label class="uk-form-label">
                    Facility Type
                  </label>
                  <select
                    v-model="data.facility_type_id"
                    class="uk-width-expand custom-select"
                    :name="`facility_type_id`"
                    @change="handleSelectFacilityType"
                  >
                    <option
                      value=""
                      hidden
                    >
                      Select Facility Type
                    </option>
                    <option
                      v-for="(item, key) in listFacilityType"
                      :key="key"
                      :value="item.id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <img
                    :src="`${images}/icon/ic_chevron_down.svg`"
                    alt="ic_chevron"
                  >
                </div>
              </div>

              <div class="uk-width-1-3">
                <div class="uk-form-controls uk-position-relative withlabel">
                  <label class="uk-form-label">
                    Facility Name
                  </label>
                  <select
                    v-model="data.id"
                    class="uk-width-expand custom-select"
                    :name="`facility_type_id`" 
                    :class="list_facility.length > 0 ? '' : 'disabled'"
                    :disabled="!facility_type"
                    @change="handleSelectFacilityName"
                  >
                    <option
                      value=""
                      hidden
                    >
                      Select Facility Type
                    </option>
                    <option
                      v-for="(item, key) in list_facility"
                      :key="key"
                      :value="item.id"
                    >
                      {{ item.facility_name }}
                    </option>
                  </select>
                  <img
                    :src="`${images}/icon/ic_chevron_down.svg`"
                    alt="ic_chevron"
                  >
                </div>
              </div>

              <div class="uk-width-1-3">
                <label class="uk-form-label">
                  Export Hub
                </label>
                <div class="uk-form-controls">
                  <input
                    :name="`export_hub`"
                    :value="export_hub || ''"
                    class="uk-input readonly"
                    type="text"
                    autocomplete="off"
                    readonly
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="uk-flex uk-flex-right uk-margin-medium-top">
          <button
            class="uk-button soft-green uk-margin-right"
            @click="showCancelConfirmModal"
          >
            Cancel
          </button>
          <button
            class="uk-button green"
            :class="isCanAccessUser('edit', 'USER') ? '' : 'disabled'"
            :disabled="!isCanAccessUser('edit', 'USER')"
            @click="showSaveConfirmModal"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations ,mapGetters } from 'vuex'
import { STATUS } from '@/utils/constant'
import { isCanAccess } from '@/utils/auth'

export default {
  props: {
    id: {
      required: true,
      type: String
    },
    images: {
      required: true,
      type: String
    },
    listFacilityType: {
      required: true,
      type: Array
    },
    listRole: {
      required: true,
      type: Object
    },
    loadingDetail: {
      required: false,
      type: Boolean
    },
    setToggleModalAddEditDiscard: {
      required: true,
      type: Function
    },
    getToggleModalAddEditDiscard: {
      required: true,
      type: Object
    },
    setDataModalAddEdit: {
      required: true,
      type: Function
    }
  },
  data() {
    return {
      status: STATUS,
      isLoadingSearch: false,
      role: '',
      partner: '',
      isPhoneNumber: false,
      facility_type: '',
      export_hub: '',
      list_facility: [],
      formUser: {}
    }
  },
  computed: {
    ...mapGetters({
      detail_user: 'user/detail_user',
      listPartner: 'partner/list_partner',
      listHulling: 'hullingFacility/list_hulling',
      listPulping: 'pulpingFacility/list_pulping',
      listPlantation: 'plantation/list_plantation',
      list_facility_user: 'user/list_facility_user'
    })
  },
  watch: {
    async detail_user(val) {
      if(val) {
        this.formUser = {
          user_code: val.user_code,
          name: val.name,
          phone: val.phone,
          email: val.email,
          role_id: val.role_id,
          partner_id: val.partner_id,
          status: val.status,
          user_facility_id: '',
          facility_id: '',
          facility_type_id: ''
        }
      }
    }
  },
  async mounted() {
    await this.actionGetListPartner({ limit: 50 })
    await this.actionGetDetailUser(this.id)
    await this.actionGetListHulling({ partner_id: this.detail_user.partner_id, limit: 1000, is_all: true, is_only_partner_not_exist:true })
    await this.actionGetListPulping({ partner_id: this.detail_user.partner_id, limit: 1000, is_all: true, is_only_partner_not_exist:true })
    await this.actionGetListPlantation({ partner_id: this.detail_user.partner_id, limit: 1000, is_all: true, is_only_partner_not_exist:true })
    
    if(this.list_facility_user != null){
      this.list_facility_user?.map(item => {
        if(item.facility_type == 'Hulling Facility'){
          this.list_facility = this.listHulling 
        } else if (item.facility_type == 'Pulping Facility'){
          this.list_facility = this.listPulping
        } else {
          this.list_facility = this.listPlantation
        }
        this.facility_type = item.facility_type
        this.export_hub = this.list_facility.filter(item => item.id == this.list_facility_user[0].id )[0].export_hub.name || ''
      })
    } else {
      this.setFacilityUser([
        {
          facility_id: '',
          facility_type_id: ''
        }
      ])
    }
  },
  methods: {
    ...mapActions({
      actionGetDetailUser: 'user/getDetailUser',
      actionGetListPartner: 'partner/getListPartner',
      actionGetListHulling: 'hullingFacility/getListHulling',
      actionGetListPulping: 'pulpingFacility/getListPulping',
      actionGetListPlantation: 'plantation/getListPlantation'
    }),
    ...mapMutations({
      setFacilityUser: 'user/SET_LIST_FACILITY_USER'
    }),
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    async handleSelectFacilityType(event){
      this.export_hub = ''
      this.formUser.facility_type_id = event.target.value

      this.facility_type = this.listFacilityType.length > 0 ? this.listFacilityType.find(item => item.id == event.target.value).name : ''
      if(this.facility_type == 'Hulling Facility'){
        this.list_facility = this.listHulling    
      } else if (this.facility_type == 'Pulping Facility'){
        this.list_facility = this.listPulping
      } else {
        this.list_facility = this.listPlantation
      }
      this.export_hub = this.list_facility.filter(item => item.id == this.list_facility_user[0].id )[0].export_hub.name || ''
    },
    handleSelectFacilityName(event){
      this.formUser.facility_id = event.target.value
      this.export_hub = this.list_facility.length > 0 ? this.list_facility?.find(item => item.id == event.target.value).export_hub?.name : ''
    },
    handleStatus(event) {
      this.formUser.status = event.target.checked ? 1 : 0
    },
    async asyncFindPartner(val) {
      this.isLoadingSearch = true
      await this.actionGetListPartner({ search: val, limit: 50 })
      this.isLoadingSearch = false
    },
    handleSelectPartner(val) {
      this.formUser.partner_id = val?.id
      
    },
    handleSelectRole(val) {
      this.formUser.role_id = val?.id
    },
    handlePhoneNumber(value) {
      if (!value) {
        this.isPhoneNumber = true
      } else {
        if (value && value.length > 8) {
          this.isPhoneNumber = true
        } else {
          this.isPhoneNumber = false
        }
      }
    },
    validasiInput(value) {  
      const regex2 = /[0-9+]/g
      const serachNumber = [...value.matchAll(regex2)].join('')
      if (serachNumber.substring(0, 3) == '+62') {
        value = serachNumber.replace('+62', '0')
      } else if (serachNumber.substring(0, 2) == '62') {
        value = serachNumber.replace('62', '0')
      } else {
        value = serachNumber
      }
      if (!value) {
        this.isPhoneNumber = true
      } else {
        if (value && value.length > 8) {
          this.isPhoneNumber = true
        } else {
          this.isPhoneNumber = false
        }
      }
    },
    showCancelConfirmModal() {
      this.setToggleModalAddEditDiscard({
        targetModals: true,
        title: 'Cancel Confirmation', 
        description: 'All data will be lost if you leave. Are you sure want to leave?',
        customTitleBtnOk: 'Yes',
        customTitleBtnCancel: 'No',
        type: 'discard',
        route: 'User',
        loadingTable: false,
        loadingPopUp: false
      })
    },
    showSaveConfirmModal() {
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          this.setDataModalAddEdit(this.formUser)
          this.setToggleModalAddEditDiscard({
            targetModals: true,
            title: 'Save Confirmation', 
            description: 'Are you sure want to save this data?',
            customTitleBtnOk: 'Save',
            customTitleBtnCancel: 'Cancel',
            type: 'save',
            loadingTable: false,
            loadingPopUp: false
          })
        }
      })
    }
  }
}
</script>

<style scoped>
  .custom-select.disabled{
    background: #DEDEDE;
  }
  .form-facility-fields .uk-width-1-3 {
    max-width: 32%;
  }
  .btn-add-create-form {
    font-family: 'interMedium';
    font-weight: 600;
    font-size: 16px;
    background: transparent;
    color: #2FAC4A;
  }
  .img-fields {
    display: flex;
    justify-items: center;
    align-items: center;
    margin-top: 25px;
    cursor: pointer;
  }
  .ic-delete-form{
    width: 24px;
    height: 24px;
    max-width: 24px !important;
  }
  .uk-input:disabled{
    background: #F4F4F4 !important;
    color: #000000 !important;
  }
</style>
